import request from '@/utils/request'

/**
 * 获取物流公司列表
 * @param params
 */
export function getPaymentList(params) {
  return request({
    url: 'seller/shops/logi-companies',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 开启物流公司
 * @param logi_id
 */
export function openSwitch(logi_id) {
  return request({
    url: `seller/shops/logi-companies/${logi_id}`,
    method: 'post'
  })
}

/**
 * 关闭物流公司
 * @param params
 */

export function closeSwitch(logi_id) {
  return request({
    url: `seller/shops/logi-companies/${logi_id}`,
    method: 'delete'

  })
}

/**
 * 查询退货地址
 * @param params
 */

export function getBackAdress() {
  return request({
    url: "seller/shops/refund/address/getDetail",
    method: 'get'

  })
}

/**
 * 设置退货地址
 * @param params
 */

export function setBackAdress(id, params) {
  return request({
    url: `seller/shops/refund/address/${id}`,
    method: 'put',
    data: params
  })
}

/**
 * 修改密码
 * @param params
 */

export function changePwd(params) {
  return request({
    url: `/seller/shops/security/password`,
    method: 'put',
    data: params
  })
}

/**
 * 修改绑定的手机号
 * @param params
 */

export function changeMobile(mobile, params) {
  console.log(params, "params")
  return request({
    url: `seller/shops/security/exchange-bind/${mobile}`,
    method: 'put',
    params
  })
}

/**
 * 发送手机验证码
 * @param params
 */

export function sendMobile(mobile, params) {
  return request({
    url: `seller/shops/security/bind/send/${mobile}`,
    method: 'post',
    params

  })
}


/**
 * 获得个人信息
 * @param params
 */

export function getUserInfo() {
  return request({
    url: "seller/members/get-info",
    method: 'get'
  })
}

/**
 * 保存个人信息
 * @param params
 */

export function saveUserInfo(params) {
  return request({
    url: "seller/members/edit-info",
    method: 'post',
    data: params
  })
}

/**
 * 保存头像
 * @param params
 */

export function saveUserImg(params) {
  return request({
    url: "seller/members/edit-img",
    method: 'post',
    data: params
  })
}

/**
 * 获取店铺信息
 */

export function getShopInfo() {
  return request({
    url: '/seller/shops',
    method: 'get',
  })
}

/**
 * 获取验证码
 * @params params
 */

export function getSendSmsCode(params) {
  return request({
    url: '/seller/shops/sendEditLinkSmsCode',
    method: 'post',
    data: params
  })
}

/**
 * 修改联系人信息
 * @params params
 */

export function updateContactsInfo(params) {
  return request({
    url: '/seller/shops/editLink',
    method: 'put',
    data: params
  })
}

