<template>
  <div class="shop-goods-list-page">
    <en-table-layout :tableData="pageData.data" :loading="loading" :toolbar-two="true" @sort-change="handleSortChange"
      ref="tableWrap">
      <template slot="toolbar">
        <el-form-item label="强制推荐商品排序设置">
          <el-radio style="margin-left:20px" v-model="strongPushSort" :label="0">排在智能换货前</el-radio>
          <el-radio style="margin-left:20px" v-model="strongPushSort" :label="1">排在智能换货后</el-radio>
        </el-form-item>
        <el-form-item>
          <el-button @click="setStrongPushSort" type="primary" size="small">保存</el-button>
        </el-form-item>
      </template>
      <template slot="toolbar-two">
        <el-form-item style="width:25%" label="毛利率">
          <el-input size="small" style="width:100px;" v-model="params.profit_min" placeholder="毛利率起始" />
          <span style="margin: 0 6px;letter-spacing: -2px;color:#999999;">---</span>
          <el-input size="small" style="width:100px;" v-model="params.profit_max" placeholder="毛利率结束" />
        </el-form-item>
        <el-form-item style="width:25%" label="利润率">
          <el-input size="small" v-model="params.shop_profit_min" placeholder="利润率起始" style="width:100px;" />
          <span style="margin: 0 6px;letter-spacing: -2px;color:#999999;">---</span>
          <el-input size="small" v-model="params.shop_profit_max" placeholder="利润率结束" style="width:100px;" />
        </el-form-item>
        <el-form-item class="col-auto">
          <el-input style="width: 300px;" size="medium" placeholder="输入关键字" v-model="params.keyword" clearable>
            <el-select v-model="key_word" slot="prepend" placeholder="请选择" style="width: 130px;">
              <el-option label="商品名称" value="goods_name"></el-option>
              <el-option label="前端展示名称" value="web_goods_name"></el-option>
              <el-option label="商品编号" value="goods_sn"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item class="col-auto ml-2">
          <el-button @click="search" type="primary" size="small">搜索</el-button>
          <el-button type="primary" size="small" @click="showDialog = true">添加商品</el-button>
        </el-form-item>
      </template>
      <template slot="table-columns">

        <el-table-column label="商品图片" width="60" fixed="left" class-name="goods-cover-wrapper" :key="'thumbnail'">
          <template slot-scope="{row}">
            <el-popover placement="right" trigger="hover">
              <img :src="row.thumbnail" alt style="width: 300px" />
              <img @click="opengoodsclassify(row)" :src="row.thumbnail" class="goods-cover" alt slot="reference" />
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="前端展示图片" width="70" fixed="left" class-name="goods-cover-wrapper" :key="'web_thumbnail'">
          <template slot-scope="{row,$index}">
            <div style="position:relative;">
              <div class="changBtn">
                <el-upload class="upload-demo" :action="`${MixinUploadApi}?scene=goods`" :on-success="thumbnailSuccess"
                  :show-file-list="false">
                  <el-button @click="thumbnailIndex = $index" style="padding:0;color:#fff;font-size:12px"
                    type="text">更换图片</el-button>
                </el-upload>
              </div>
              <el-popover placement="right" trigger="hover">
                <img :src="row.web_thumbnail ? row.web_thumbnail : row.thumbnail" alt style="width: 300px" />
                <img :src="row.web_thumbnail ? row.web_thumbnail : row.thumbnail" class="goods-cover" alt
                  slot="reference" />
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商品名称" width="180" :show-overflow-tooltip="true" fixed="left" :key="'goods_name'">
          <template slot-scope="scope">{{ scope.row.goods_name }}</template>
        </el-table-column>
        <el-table-column label="前端展示名称" width="210" :key="'web_goods_name'">
          <template slot-scope="{row,$index}">
            <el-input style="width:150px" v-if="activeNameIndex === $index" v-model="restrictNum" size="mini"
              @focus="restrictChang($index, 1)"></el-input>
            <el-tooltip v-else-if="!row.web_goods_name" class="item" effect="dark" :content="row.goods_name"
              placement="top">
              <el-input style="width:150px" :value="row.goods_name" size="mini"
                @focus="restrictChang($index, 1)"></el-input>
            </el-tooltip>
            <el-tooltip v-else class="item" effect="dark" :content="row.web_goods_name" placement="top">
              <el-input style="width:150px" :value="row.web_goods_name" size="mini"
                @focus="restrictChang($index, 1)"></el-input>
            </el-tooltip>
            <el-button v-show="activeNameIndex === $index" class="icon-btn" icon="el-icon-check" circle
              @click="restrictBlur($index, 1)"></el-button>
            <el-button v-show="activeNameIndex === $index" class="icon-btn" icon="el-icon-close" circle
              @click="restrictCancel($index)"></el-button>
          </template>
        </el-table-column>
        <el-table-column :key="'good_sn'" label="商品编号" width="120">
          <template slot-scope="scope">{{ scope.row.good_sn }}</template>
        </el-table-column>
        <el-table-column label="商品分类" width="120">
          <template slot-scope="scope">{{ scope.row.cat_name }}</template>
        </el-table-column>
        <el-table-column label="数量" width="150" :key="'force_referrals_num'">
          <template slot-scope="{row,$index}">
            <el-input style="width:90px" v-if="activeNumIndex === $index" v-model="restrictNum" size="mini"
              @input="changNum($event, $index)" @focus="restrictChang($index, 2)"></el-input>
            <el-input style="width:90px" v-else :value="row.force_referrals_num" size="mini"
              @focus="restrictChang($index, 2)"></el-input>
            <el-button v-show="activeNumIndex === $index" class="icon-btn" icon="el-icon-check" circle
              @click="restrictBlur($index, 2)"></el-button>
            <el-button v-show="activeNumIndex === $index" class="icon-btn" icon="el-icon-close" circle
              @click="restrictCancel($index)"></el-button>
          </template>
        </el-table-column>
        <el-table-column label="商品来源" :key="'goods_source'">
          <template slot-scope="{row}">
            <template v-if="row.goods_source === 0">平台方</template>
            <template v-if="row.goods_source === 1">京东</template>
            <template v-if="row.goods_source === 9">自有</template>
          </template>
        </el-table-column>
        <el-table-column v-if="parentLogin === 1" label="会员价" width="110" prop="price" sortable="custom" :key="'price'">
          <template slot-scope="scope">{{ scope.row.price | unitPrice('￥') }}</template>
        </el-table-column>
        <el-table-column label="分销价" width="110" :key="'enterprisePrice'" prop="enterprisePrice" sortable="custom">
          <template slot-scope="scope">{{ scope.row.enterprise_revise_price | unitPrice('￥') }}</template>
        </el-table-column>
        <el-table-column label="市场价" width="110" prop="mktprice" sortable="custom" :key="'mktprice'">
          <template slot-scope="scope">{{ scope.row.mktprice | unitPrice('￥') }}</template>
        </el-table-column>
        <el-table-column v-if="parentLogin === 1" label="毛利率" width="110" prop="enterprise_profit" sortable="custom"
          :key="'enterprise_profit'">
          <template slot-scope="scope">{{ scope.row.enterprise_profit.toFixed(2) || 0 }}%</template>
        </el-table-column>
        <el-table-column label="销售价" width="110" :key="'revise_price'" prop="revise_price" sortable="custom">
          <template slot-scope="scope">
            <span>{{ scope.row.revise_price | unitPrice('￥') }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="parentLogin === 1" label="利润率" width="110" prop="profitMargin" sortable="custom"
          :key="'shop_profit'">
          <template slot-scope="scope">{{ scope.row.shop_profit.toFixed(2) || 0 }}%</template>
        </el-table-column>
        <el-table-column label="操作" width="80" fixed="right" :key="'opt'">
          <template slot-scope="scope">
            <el-button size="mini" type="danger" @click="handleDeleteCombo(scope.row)">移除</el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-size="pageData.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="pageData.data_total" />
    </en-table-layout>
    <en-goods-picker-fx :show="showDialog" :showChooseBtn="false" :default-data="pageData.data"
      @close="showDialog = false" @chooseAll="allChoose" />
    <!-- 商品详情弹窗 -->
    <el-dialog custom-class="goodsclassify" :visible.sync="dialogVisible" :close-on-click-modal="false"
      :close-on-press-escape="false" append-to-body width="375px">
      <div slot="title" class="goods-picker-title">
        <span></span>
        商品详情
      </div>
      <!-- <goodsclassify v-if="dialogVisible" :formData="goodsclassifyData"></goodsclassify> -->
      <goods-detail v-if="dialogVisible" :formData="goodsclassifyData"></goods-detail>
    </el-dialog>
  </div>
</template>

<script>
import * as API_goods from "@/api/goods";
import * as API_Order from "@/api/order";
import * as API_Combo from "@/api/combo-card-shop/combo";
import * as API_ShopSetting from "@/api/shopSetting";
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import goodsclassify from "@/components/GoodsClassify/goodsclassify";
import goodsDetail from "@/components/GoodsDetail/GoodsDetail";
export default {
  name: "strongPush",
  components: {
    EnTableLayout,
    XDialog,
    goodsclassify,
    goodsDetail
  },
  data () {
    return {
      dialogVisible: false,//商品详情弹窗
      goodsclassifyData: {},//商品详情传参
      activeNumIndex: null,
      activeNameIndex: null,
      goodsData: [],
      showDialog: false,
      strongPushSort: null,
      key_word: "goods_name",
      /** 列表loading状态 */
      loading: false,
      /** 列表参数 */
      params: {
        shop_goods_status: 1,
        force_referrals: 1,
        profit_min: "",
        profit_max: "",
        shop_profit_min: "",
        shop_profit_max: "",
        page_no: 1,
        page_size: 20,
        keyword: "",
        sort: "def_desc"
      },

      /** 列表分页数据 */
      pageData: {},

      /**是否是企业跳转过来 */
      parentLogin: 0,
      restrictNum: "",
      thumbnailIndex: null
    };
  },
  created () {
    this.isPermission()
    API_ShopSetting.getShopInfo().then(res => {
      console.log(res)
      this.strongPushSort = res.force_referrals;
    });
    API_goods.getLoginType().then(res => {
      this.parentLogin = res.parentLogin;
    });
  },
  activated () {
    this.GET_StrongPushList();
  },
  mounted () {
    this.GET_StrongPushList();
  },
  methods: {
    opengoodsclassify (row) {//打开商品详情弹窗
      this.goodsclassifyData = {
        from: "1",
        goods_id: row.goods_id,
        good_sn: row.good_sn,
        revise_price: row.revise_price,
        shop_profit: row.shop_profit,
        islogin: true,//是否单独登录
        distribution_rate: row.distribution_rate,
      }
      this.dialogVisible = true
    },
    // 判断是否有销售开卡权限
    isPermission () {
      API_Order.getShopExtAuth().then(res => {
        console.log(res, res.force_exchange_goods_open)
        if (res.force_exchange_goods_open !== "OPEN") {
          this.$message.error(
            "平台已关闭强制推荐功能，如有其他问题，请联系平台。"
          );
          setTimeout(() => {
            this.$store.getters.permission_routers.forEach(item => {
              if (item.path === "/combo-card-shop/shop-goods") {
                // 关闭菜单
                item.children.forEach((value, index) => {
                  console.log(value.path)
                  if (value.path === "strong-push") {
                    item.children.splice(index, 1);
                    this.$router.push("/combo-card-shop/shop-goods/goods-list");
                  }
                });
              }
            });
          }, 3000);
        } else {
          return true;
        }
      });
    },
    thumbnailSuccess (res) {
      this.pageData.data[this.thumbnailIndex].web_thumbnail = res.url;
      // this.totalData = [this.baseInfoForm];
      this.restrictBlur(this.thumbnailIndex, 0);
      console.log(res, this.thumbnailList);
    },
    restrictBlur (i, type) {
      console.log(this.restrictNum);
      let params = {};
      switch (type) {
        case 0:
          params = {
            edit_client_original_goods_name_force_referrals_num: 0,
            web_original: this.pageData.data[i].web_thumbnail
          };
          break;
        case 1:
          this.pageData.data[i].web_goods_name = !this.restrictNum
            ? this.pageData.data[i].goods_name
            : this.restrictNum;
          params = {
            edit_client_original_goods_name_force_referrals_num: 1,
            web_goods_name: this.pageData.data[i].web_goods_name
          };
          break;
        case 2:
          this.pageData.data[i].force_referrals_num = !this.restrictNum
            ? 1
            : this.restrictNum;
          params = {
            edit_client_original_goods_name_force_referrals_num: 2,
            force_referrals_num: this.pageData.data[i].force_referrals_num
          };
          break;
        default:
          break;
      }
      API_goods.setShopGoods(this.pageData.data[i].id, params).then(res => {
        this.activeNumIndex = null;
        this.activeNameIndex = null;
        this.restrictNum = "";
      });
    },
    changNum (e, i) {
      console.log(e, this.restrictNum);
      this.restrictNum = e.match(/^[1-9]\d*$/g);
      this.restrictNum = this.restrictNum[0];
      this.$forceUpdate();
    },
    restrictCancel (i) {
      this.activeNumIndex = null;
      this.activeNameIndex = null;
      this.restrictNum = "";
    },
    restrictChang (i, type) {
      if (type === 1) {
        this.activeNameIndex = i;
        this.activeNumIndex = null;
        this.restrictNum =
          `${this.pageData.data[i].web_goods_name}` === ""
            ? ""
            : this.pageData.data[i].web_goods_name;
      } else {
        this.activeNumIndex = i;
        this.activeNameIndex = null;
        this.restrictNum =
          `${this.pageData.data[i].force_referrals_num}` === ""
            ? "1"
            : this.pageData.data[i].force_referrals_num;
      }
      console.log(this.restrictNum);
    },
    /** 保存商品选择器选择的商品 */
    allChoose (list) {
      console.log(list, "list");
      const goods_ids = list
        .map(item => {
          return item.goods_id;
        })
        .join(",");
      API_goods.addRemoveForceReferralsGoods({ goods_ids, type: 1 }).then(
        res => {
          console.log(res);
          if (res.code === 200) {
            this.GET_StrongPushList();
          }
        }
      );
      console.log(this.goodsData);
    },
    setStrongPushSort () {
      API_Combo.forceReferrals({ force_referrals: this.strongPushSort }).then(
        res => {
          console.log(res);
          this.$message.success("保存成功");
        }
      );
    },

    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_StrongPushList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_StrongPushList();
    },

    search () {
      this.params.page_no = 1;
      this.GET_StrongPushList();
    },

    GET_StrongPushList () {
      this.loading = true;
      let params = { ...this.params };
      params[this.key_word] = params.keyword;
      console.log(params, this.key_word);
      // this.params.shopId = this.$store.getters.shopInfo.shop_id;
      API_goods.forceReferralsList(params).then(response => {
        if (response.data.length === 0 && response.page_no > 1) {
          this.params.page_no--;
          return this.GET_StrongPushList();
        }
        this.loading = false;
        this.pageData = response;
        this.$nextTick(() => {
          this.$refs.tableWrap.$refs.table.doLayout();
        });
      });
    },

    /** 删除商品 */
    handleDeleteCombo (row) {
      this.$confirm("确定要移除该商品吗？", "提示", {
        type: "warning"
      }).then(() => {
        API_goods.addRemoveForceReferralsGoods({
          goods_ids: row.goods_id,
          type: 0
        }).then(res => {
          console.log(res);
          if (res.code === 200) {
            this.GET_StrongPushList();
          }
        });
      });
    },

    handleSortChange ({ prop, order }) {
      switch (prop) {
        case "mktprice":
          this.params.sort = `market_${order.includes("asc") ? "asc" : "desc"}`;
          break;
        case "enterprise_profit":
          this.params.sort = `profit_${order.includes("asc") ? "asc" : "desc"
            }`;
          break;
        case "revise_price":
          this.params.sort = `revisePrice_${order.includes("asc") ? "asc" : "desc"
            }`;
          break;
        default:
          if (order) {
            this.params.sort = `${prop}_${order.includes("asc") ? "asc" : "desc"
              }`;
          } else {
            this.params.sort = "";
          }
          break;
      }
      console.log(this.params.sort, order);
      this.GET_StrongPushList();
    }
  }
};
</script>
<style type="text/scss" lang="scss" scoped>
@import "@/components/GoodsClassify/styles";
</style>
<style lang="scss" scoped>
/deep/.goodsclassify {
  .el-dialog__body {
    padding: 0 !important;
  }
}

.num {
  cursor: pointer;
  color: #2155d5;
  text-decoration: underline;
}

.num:hover {
  text-decoration: underline;
}

.icon-btn {
  border: 1px solid #5463a2;
  padding: 0;
  margin-left: 0;
  box-sizing: border-box;

  .el-icon-circle-plus-outline,
  .el-icon-remove-outline {
    font-size: 22px;
  }
}

.changBtn {
  position: absolute;
  top: 20px;
  left: 6px;
  width: 80%;
  background-color: rgba(0, 0, 0, 0.2);
}
</style>
